












































































































































import { Component, Vue, Ref } from 'vue-property-decorator'
import { listCategories, pageVideoList, getVideoDetails } from '@/api'
import { getPlatform } from '@/utils'
import PopUp from '@/components/PopUp.vue'
import ScrollTop from '@/components/GoTop.vue'
@Component({
  components: {
    PopUp,
    ScrollTop
  }
})
export default class VideoSharing extends Vue {
  @Ref('video')
  videoRef!: HTMLVideoElement

  pla = getPlatform()

  // 是否禁用点我获取按钮
  isDisabled = false

  // 视频地址
  linkUrl = ''

  // 视频封面
  coverUrl = ''

  // 是否显示播放按钮
  isPlaybtn = true

  // 是否显示引导
  isGuidance = false

  // tab栏
  tabs: any = []

  // 列表数据
  list: any = []

  // 是否处在加载状态
  loading = false

  // 是否已加载完成
  finished = false

  // 是否加载失败
  error = false

  // 分页
  pageNum = 1

  // 每页条数
  pageSize = 10

  // 数据总条数
  total = 0

  // 试看弹窗
  isSee = false

  // 试看结束弹窗
  isFinish = false

  // 设置弹窗
  isSet = false

  created() {
    // 获取tab栏
    this.getlistCategories()

    // 获取视频详情
    this.getVideoDetails()

    // 获取当前语言
  }

  get language() {
    return this.$i18n.locale
  }

  // 获取视频详情
  async getVideoDetails() {
    const { data } = await getVideoDetails({
      videoId: this.$route.query.videoId
    })
    const { linkUrl, coverUrl } = data[0]
    console.log(linkUrl, '获取视频详情')
    this.linkUrl = linkUrl
    this.coverUrl = coverUrl
  }

  // 点我获取视频
  handelPlay() {
    this.isDisabled = true
    this.isSee = true
  }

  // 设置来电秀
  handelSet() {
    this.isSet = true
  }

  // 试看事件
  handelSee() {
    this.isSee = false
    this.isPlaybtn = false
    this.videoRef.play()
    setTimeout(() => {
      this.videoRef.pause()
      this.isPlaybtn = true
      this.isFinish = true
      this.isDisabled = false
    }, 10000)
  }

  // 暂不下载事件
  handelnoDown() {
    this.isFinish = false
    this.isSet = false
  }

  // 下载事件
  handleDown() {
    this.isSee = false
    this.isFinish = false
    this.isSet = false
    if (this.pla === 'weixin' || this.pla === 'qq') {
      this.isGuidance = true
    } else {
      const loadDateTime = new Date().getTime()
      // 如果用户打开App失败，直接跳转下载
      const timer = setTimeout(() => {
        const timeOutDateTime = new Date().getTime()
        if (timeOutDateTime - loadDateTime < 3000) {
          window.location.href = process.env.VUE_APP_ALLO
        } else {
          return window.close()
        }
      }, 1500)

      // 离开页面清除定时器
      window.onbeforeunload = function() {
        clearTimeout(timer)
      }
      // 如果用户已经安装App直接跳转App
      window.location.href = 'allo://tel.top/home/callshow?select=2'
      this.isGuidance = false
    }
  }

  // 切换tab栏
  handelSwitchover() {
    this.onRefresh()
  }

  // 获取tab栏
  async getlistCategories() {
    const { data } = await listCategories({
      type: 1
    })
    this.tabs = JSON.parse(JSON.stringify(data))
    const temp = this.tabs.filter((item: any) => {
      if (item.labelType !== 2) {
        return item
      }
    })
    this.tabs = temp
    console.log(this.tabs, 'tab栏')
  }

  // 获取列表数据
  async getpageVideoList() {
    const { data } = await pageVideoList({
      pageNum: this.pageNum,
      pageSize: this.pageSize,
      categoryId: -1
    })
    if (data.list.length == 0 || data.list == null) {
      // 判断获取数据条数若等于0
      this.list = [] // 清空数组
      this.finished = true // 停止加载
    }
    // 若数据条数不等于0
    this.total = data.total // 给数据条数赋值
    this.list.push(...data.list) // 将数据放入list中
    this.loading = false // 加载状态结束
    // 如果list长度大于等于总数据条数,数据全部加载完成
    if (this.list.length >= data.total) {
      this.finished = true // 结束加载状态
    }
  }

  // 被 @load调用的方法
  onLoad() {
    this.getpageVideoList() // 调用上面方法,请求数据
    this.pageNum++ // 分页数加一
    this.finished = false
  }
  // 加载失败调用方法
  onRefresh() {
    this.finished = false // 清空列表数据
    this.loading = true // 将 loading 设置为 true，表示处于加载状态
    this.pageNum = 1 // 分页数赋值为1
    this.list = [] // 清空数组
    this.onLoad() // 重新加载数据
  }
}
